import {MatDateFormats} from '@angular/material/core';

export const datePickerFormat: MatDateFormats = {
  parse: {
    dateInput: {month: 'numeric', year: 'numeric', day: 'numeric'}
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'input',
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};
